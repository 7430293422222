import React, {useState} from 'react'
import { appconfig } from '../karumaconfig'
import { Button, makeStyles } from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import { format } from 'date-fns'
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import StoreIcon from '@material-ui/icons/Store';
import { LiveTokenBalance } from '../components/LiveTokenBalance';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import { useMoralis } from 'react-moralis';


var drawerWidth = 50

const useStyles = makeStyles((theme) => {
  return {
    page: {
      background: '#f9f9f9',
      width: '100%',
      padding: theme.spacing(3),
    },
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    active: {
      background: '#f4f4f4'
    },
    title: {
      padding: theme.spacing(2),
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    date: {
      flexGrow: 1
    },
    pagetitle: {
      flexGrow: 1
    },
    tokenBalance: {
      flexGrow: 1
    },
    spacerbox: {
      height: 30
    },
    toolbar: theme.mixins.toolbar,
    avatar: {
      marginLeft: theme.spacing(2)
    }
  }
})

export default function Layout({ children }) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  //const [drawerWidth, setDrawerWidth] = useState(50);
  const [pageTitle, setPageTitle] = useState("Donate");
  

  const { authenticate, isAuthenticated, user, logout } = useMoralis();
  var menuItems;

  function handleLogOut(){
    logout();
    history.push("/");
  }

  if (!(isAuthenticated && user)) {
    menuItems = [
      { 
        text: 'Login', 
        icon: <ExitToAppIcon color="secondary" />, 
        path: '/login' 
      },
    ]
  } else {
      menuItems = [
        { 
          text: 'Donate', 
          icon: <HowToVoteIcon color="secondary" />, 
          path: '/donate' 
        },
        { 
          text: 'Volunteer', 
          icon: <EmojiPeopleIcon color="secondary" />, 
          path: '/volunteer' 
        },
        { 
          text: 'Karma Certificates', 
          icon: <DescriptionOutlinedIcon color="secondary" />, 
          path: '/karma-certificates' 
        },
        { 
          text: 'Marketplace', 
          icon: <StoreIcon color="secondary" />, 
          path: '/marketplace' 
        },
        { 
          text: 'All Eyes', 
          icon: <VisibilityIcon color="secondary" />, 
          path: '/all-eyes' 
        },
        { 
          text: 'Profile', 
          icon: <AccountBoxIcon color="secondary" />, 
          path: '/profile' 
        },
      ];
    }

  return (
    <div className={classes.root}>
      {/* app bar */}
      <AppBar 
        position="fixed" 
        className={classes.appBar}
        elevation={0}
        color="primary"
      >
        <Toolbar>
          <Typography className={classes.pagetitle}>
            {pageTitle}
          </Typography>
          {/* <Typography className={classes.date}>
            {format(new Date(), 'do MMMM Y')}
          </Typography> */}
          <Typography variant="subtitle2" className={classes.tokenBalance}>
          { (isAuthenticated && user) && <LiveTokenBalance balanceExtend="EthTokenBalance" address={user.attributes.ethAddress} tokenaddress = {appconfig.chain.KRTAddress}/>}
          </Typography>
           {isAuthenticated ? <>
          <Typography></Typography>
          <Button variant="contained" color="secondary" onClick={() => handleLogOut()}>Logout</Button>
          </>
          : <Button variant="contained" color="secondary" onClick={() => authenticate()}>Login with wallet</Button>}
          {/* <Avatar className={classes.avatar} src="/mario-av.png" /> */}
        </Toolbar>
      </AppBar>

      {/* side drawer */}
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{ paper: classes.drawerPaper }}
        anchor="left"
      >
        <div>
          <Typography variant="h5" className={classes.title}>
            {/* Karmic Account */}
             
          </Typography>
        </div>
        <Box color="text.primary" className={classes.spacerbox}>

        </Box>

        {/* links/list section */}
        <List>
          {menuItems.map((item) => (
            <ListItem 
              button 
              key={item.text} 
              onClick={() => {history.push(item.path)
                setPageTitle(item.text)
                } }
              className={location.pathname == item.path ? classes.active : null}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          ))}
        </List>
        
      </Drawer>

      {/* main content */}
      <div className={classes.page}>
        <div className={classes.toolbar}></div>
        { children }
      </div>
    </div>
  )
}
