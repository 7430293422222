import React, { useEffect, useState } from 'react'

import {useMoralis, useMoralisQuery} from "react-moralis";

import { appconfig } from '../karumaconfig'

import useTokenGetURI from '../hooks/useTokenGetURI'
import useFetch from '../hooks/useFetch'

import { Container, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';

import Divider from '@material-ui/core/Divider';

import Button from '@material-ui/core/Button'

import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CancelIcon from '@material-ui/icons/Cancel';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { yellow, green, pink, blue } from '@material-ui/core/colors'
import SendIcon from '@material-ui/icons/Send';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    avatar: {
    backgroundColor: (nft) => {
      return blue[500]
    }},
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    margined: {
        margin: 10, 
      },
    sendFormField: {
      width: '70%',
    },
    sendFormButton: {
      verticalAlign: 'middle',
      width: '30%',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
}))


export default function MarketNFTCard(props) {
  const { authenticate, Moralis, isAuthenticated, isInitialized, user } = useMoralis();
  const { data: tokenURI, isPending, error} = useTokenGetURI(props.nftId);
  const classes = useStyles(props.nft);
  var [tokenMetaData, setTokenMetaData] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [address, setAddress] = useState();
  const [addressError, setAddressError] = useState();
  //const { data: tokenMetaData, isPending: metaDataIsPending, error: metaDataError } = useFetch(tokenURI);
 
  var marketInst;
  //var [tokenURI, setTokenURI] = useState();
  var tokencontract;
  const [offer, setOffer] = useState();
  const web3Lib = new Moralis.Web3;
  
  //console.log(props);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  async function getNFTName(tokenId){
    let web3 = await Moralis.Web3.enable();
    tokencontract = await new web3.eth.Contract(appconfig.kmcabi, appconfig.chain.KMCAddress);
    tokencontract.methods.name().call({from: user.attributes.ethAddress}).then((result) => {
        console.log(result);
        //setTokenURI(result);
        return result;
    })
}

async function getTokenURI(tokenId){
    try{
        let web3 = await Moralis.Web3.enable();
        tokencontract = await new web3.eth.Contract(appconfig.kmcabi, appconfig.chain.KMCAddress);
        tokencontract.methods.tokenURI(tokenId).call({from: user.attributes.ethAddress}).then((result) => {
            console.log(result);
            return result;
        })
    }
    catch(err){
        console.log(err)
    }
}
  
  useEffect( () => {

    async function loadData(){

        try{
        let web3 = await Moralis.Web3.enable();
        console.log(web3);
        marketInst = await new web3.eth.Contract(appconfig.kmcmarketplaceabi, appconfig.chain.KMCMarketAddress);
        console.log(marketInst);

        tokencontract = await new web3.eth.Contract(appconfig.kmcabi, appconfig.chain.KMCAddress);
        console.log("NFT contract: ");
        console.log(tokencontract);

        setOffer(await getOfferInfo(props.nftId));
        }
        catch(err){
            console.log(err)
        }

        console.log(offer);

    }

    function wrapper(){

        loadData();

        fetch(tokenURI)
        .then(res => {
          //console.log(res);
          return res.json();
        })
        .then(data => {
          if (data == null){
              data = { name: "Unnamed", description : "No Description"}
          }
          //console.log(data);
          setTokenMetaData(data);
        })

        }
        
        //console.log(nft.attributes.token_uri)
    

    if(isInitialized){
        console.log(tokenURI);
        wrapper();

    }

}, [isInitialized, isAuthenticated, tokenURI])

    async function getOfferInfo(tokenId){
        let web3 = await Moralis.Web3.enable();
        console.log(web3);
        marketInst = await new web3.eth.Contract(appconfig.kmcmarketplaceabi, appconfig.chain.KMCMarketAddress);
        try{
        let result = await marketInst.methods.getOffer(parseInt(tokenId)).call({from: user.attributes.ethAddress})

            // console.log(result);
            return result;
        } catch(err) {
            console.log(err);
        }
    }

    async function buyNFT(tokenId){
        let web3 = await Moralis.Web3.enable();
        console.log(web3);
        marketInst = await new web3.eth.Contract(appconfig.kmcmarketplaceabi, appconfig.chain.KMCMarketAddress);
        marketInst.methods.buyNFT(tokenId).send({from: user.attributes.ethAddress, value: offer.price}, function(err, txHash){
            if (err){
                console.log(err)
            } else {
                console.log("Buy request sent with txHash: " + txHash)
            }
        });
    
    }

    async function cancelOffer(tokenId){
        let web3 = await Moralis.Web3.enable();
        console.log(web3);
        marketInst = await new web3.eth.Contract(appconfig.kmcmarketplaceabi, appconfig.chain.KMCMarketAddress);
        marketInst.methods.removeOffer(tokenId).send({from: user.attributes.ethAddress}, function(err, txHash){
            if (err){
                console.log(err)
            } else {
                console.log("setOffer request sent with txHash: " + txHash)
            }
        });
    }

  if (tokenMetaData != null){
    //console.log(tokenMetaData);
    return (
      <div>
        <Card id={props.nftId} elevation={1}>
          <CardHeader
            avatar = {<Avatar alt="Token avatar" src={tokenMetaData.image} />}
            title={tokenMetaData.name}
            subheader={" with id: " + props.nftId}
          />
          <Divider variant="middle" />
          <CardMedia
            className={classes.media}
            image={tokenMetaData.image}
            title=""
          />
          <Divider variant="middle" />
          
          <CardActions disableSpacing>
            {(offer.seller.toLowerCase() != user.attributes.ethAddress.toLowerCase()) && <IconButton aria-label="buy" onClick={() => {buyNFT(props.nftId)}}>
              <ShoppingCartIcon />
            </IconButton>}
            {(offer.seller.toLowerCase() == user.attributes.ethAddress.toLowerCase()) && <IconButton aria-label="cancel" onClick={() => {cancelOffer(props.nftId)}}>
             <Typography className = {classes.margined}>Cancel Offer</Typography><CancelIcon />
            </IconButton>}
            { offer && <Typography>{web3Lib.utils.fromWei(offer.price, 'ether')}</Typography>}
            {/* <IconButton aria-label="share">
              <ShareIcon />
            </IconButton> */}
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" />
        <CardContent>
            <Typography variant="body2" color="textSecondary">
              { tokenMetaData.description }
            </Typography>
          </CardContent>
      </Collapse>
        </Card>
      </div>
    )
  } else {
    return(
    <div>
      <Card elevation={1}>
        <CardHeader
          title="Loading"
          //subheader={nft.category}
        />
        <Divider variant="middle" />
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            Fetching metadata...
          </Typography>
        </CardContent>
      </Card>
    </div>)
  }
}