import React, { useEffect, useState } from 'react'
import { appconfig } from '../karumaconfig'
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom'
import {useMoralis, useMoralisQuery} from "react-moralis";
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button';
import MarketNFTCard from '../components/MarketNFTCard'
import { Typography } from '@material-ui/core';
import Masonry from 'react-masonry-css'
import { getDefaultNormalizer, getQueriesForElement } from '@testing-library/react';




export default function NFTMarketPlace() {
  //var [NFTs, setNFTs] = useState([]);
  var [data, setData] = useState(null);

  var marketcontract;
  var tokencontract;
  var [marketPlaceApproved, setMarketPlaceApproved] = useState();
  var NFTOnSale;

  const { authenticate, Moralis, isAuthenticated, isInitialized, user } = useMoralis()
  const history = useHistory();

  async function loadData(){

    let web3 = await Moralis.Web3.enable();
    marketcontract = await new web3.eth.Contract(appconfig.kmcmarketplaceabi, appconfig.chain.KMCMarketAddress);
    console.log(marketcontract);

    tokencontract = await new web3.eth.Contract(appconfig.kmcabi, appconfig.chain.KMCAddress);
    console.log("NFT contract: ");
    console.log(tokencontract);

    console.log(user.attributes.ethAddress);
    console.log(appconfig.chain.KMCMarketAddress);
    let isApproved = await tokencontract.methods.isApprovedForAll(user.attributes.ethAddress, appconfig.chain.KMCMarketAddress).call();
    setMarketPlaceApproved(isApproved);
    console.log("marketPlaceApproved: ");
    console.log(marketPlaceApproved);

    NFTOnSale = await marketcontract.methods.getAllTokenOnSale().call();
    console.log(NFTOnSale);

    setData(NFTOnSale);

  }

  
  useEffect(() => {


    async function wrapper(){
        
        loadData();

   }
  
    if(isInitialized){
      
      if (!isAuthenticated) {
        history.push("/login");
      } else {
        wrapper();
      }
    }
  }, [isInitialized])

  async function initiateApproval(){
    let web3 = await Moralis.Web3.enable();
    tokencontract = await new web3.eth.Contract(appconfig.kmcabi, appconfig.chain.KMCAddress);
    tokencontract.methods.setApprovalForAll(appconfig.chain.KMCMarketAddress, true).send({from: user.attributes.ethAddress}, function(err, txHash){
            if (err){
                console.log(err)
            } else {
                console.log("ApproveForAll request send with txHash: " + txHash)
            }
        });
    }
  
    async function getNFTName(tokenId){
        let web3 = await Moralis.Web3.enable();
        tokencontract = await new web3.eth.Contract(appconfig.kmcabi, appconfig.chain.KMCAddress);
        tokencontract.methods.name().call({from: user.attributes.ethAddress}).then((result) => {
            console.log(result);
            return result;
        })
    }

    async function getTokenURI(tokenId){
        let web3 = await Moralis.Web3.enable();
        tokencontract = await new web3.eth.Contract(appconfig.kmcabi, appconfig.chain.KMCAddress);
        tokencontract.methods.tokenURI(tokenId).call({from: user.attributes.ethAddress}).then((result) => {
            console.log(result);
            return result;
        })
    }

  const breakpoints = {
    default: 3,
    1100: 2,
    700: 1
  };

  

  if (data == null) {
    return (<Typography>Loading...</Typography>)
    } else {

        if (marketPlaceApproved != true){
            return (
                <>
                <Typography>You need to authorize the marketplace to trade your tokens before you can use it.</Typography>
                <Button 
                color="primary" 
                variant="contained" 
                onClick = {()=>{initiateApproval()}}>Authorize</Button>
                </>
            )
            
        } else {
            console.log(data);
            if (data.length == 0){
                return(
                    <Typography>Nothing for sale right now</Typography>
                )
            }
        }

        


    return (
      <Container>
        {/* {isLoading == true && (
          <div>Loading...</div>
        )} */}
        <Masonry
            breakpointCols={breakpoints}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {data.map(NFT => (
                <div key={NFT}>
                  <MarketNFTCard nftId={NFT} />
                </div>
              ))}
          </Masonry>
      </Container>
    )
  }

}