import { useState, useEffect } from 'react';
import {useMoralis} from "react-moralis";
import { appconfig } from '../karumaconfig'

const useTokenGetURI = (tokenId) => {
    const { authenticate, Moralis, isAuthenticated, isInitialized, user } = useMoralis();
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTimeout(async () => {
        let web3 = await Moralis.Web3.enable();
        let tokencontract = await new web3.eth.Contract(appconfig.kmcabi, appconfig.chain.KMCAddress);
        tokencontract.methods.tokenURI(tokenId).call({from: user.attributes.ethAddress}).then((result) => {
        //console.log(result);

        setData(result);

        setIsPending(false);
        
        setError(null);
        })
        .catch(err => {
            // auto catches network / connection error
            setIsPending(false);
            setError(err.message);
          })
      
    }, 1000);
  }, [isInitialized])

  return { data, isPending, error };
}
 
export default useTokenGetURI