import React, {useEffect, useState} from 'react'
import {useMoralis, useMoralisQuery} from "react-moralis";
import { appconfig } from '../karumaconfig'
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SendIcon from '@material-ui/icons/Send';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import { Container, Typography } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles({
  table: {
    minWidth: 250,
  },
  tableContainer:{
    marginTop: 10,
    marginBottom: 10,
  },
  tableTitle:{
    marginTop: 15,
    marginBottom: 10,
  },
});


export default function AllEyes() {
    const classes = useStyles();
    var [data, setData] = useState();
    var [projectname, setProjectname] = useState();
    var query;
    var subscription;
    var rows;
    //console.log(props)


    const { authenticate, Moralis, isAuthenticated, isInitialized, user } = useMoralis()
    const history = useHistory();
    

    

    useEffect(() => {

        async function loadData(){
            
          //const results = await Moralis.Cloud.run("AllEyes", {});

          const Donation = Moralis.Object.extend("Donation");
          query = new Moralis.Query(Donation);
          query.notEqualTo("status","pledged");

          query.find().then( (results) => {
            //console.log(results);
            setData(results);
            // results.map(donation => {
            //   console.log({"id" : donation.id});
            // })
          });

          

          // setData(results);
          // console.log(results);
            

        }

      
        async function wrapper(){
            
            loadData();

            // subscription = await query.subscribe();

            // subscription.on('open', () => {
            //     //console.log('subscription opened');
            //    });
        
            // subscription.on('create', (object) => {
            //     //console.log('object updated');
            //     loadData();
            //   });

            // subscription.on('update', (object) => {
            // //console.log('object updated');
            //     loadData();
            // });

            // subscription.on('delete', (object) => {
            // //console.log('object updated');
            //     loadData();
            // });
    
        }
    
        if(isInitialized){

                wrapper();


        }

        }, [isInitialized])
       
      
        const columns = [
          { field: 'updatedAt', headerName: 'Date', width: 135},
          {
            field: 'reference',
            headerName: 'Recipient',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 250,
            valueGetter: (params) => {
              let result = [];
              if (params.row.attributes.reference) {
                result.push(params.row.attributes.reference)
                
              } else {
                result = ["Unknown"];
              }
              return result;
            },
          },
          { field: 'amount', headerName: 'Amount', width: 150,
            valueGetter: (params) => {
              let result = [];
              if (params.row.attributes.amount) {
                result.push(params.row.attributes.amount/(10**appconfig.KRTDecimals))
                
              } else {
                result = ["Unknown"];
              }
              return result;
            },
          },
          { field: 'status', headerName: 'Status', width: 150,
            valueGetter: (params) => {
              //console.log({ params });
              let result = [];
              if (params.row.attributes.status) {
                result.push(params.row.attributes.status)
                
              } else {
                result = ["Unknown"];
              }
              return result;
            }, 
          },
          { field: 'sendTx', headerName: 'Transaction', width: 550,
          valueGetter: (params) => {
            //console.log({ params });
            let result = [];
            if (params.row.attributes.sendTx) {
              //let txlink = "<a href=\"https://ropsten.etherscan.io/tx/" + params.row.attributes.sendTx + "\">"  + params.row.attributes.sendTx + "</a>"
              result.push(params.row.attributes.sendTx);
              
            } else {
              result = ["Unknown"];
            }
            return result;
          },  },
        ];



        

        rows = data;
      

        if (data != null) {
            if (data.length > 0){  
            //console.log(data)
            return(
              <>
              <Container>
                <Typography>Your karmic account is audited via the all eyes principle. All users can see all donations sent without the sender address including the corresponding transaction on the block chain.</Typography>
              </Container>
              <div style={{ margin: 10, height: 400, width: '100%' }}>
              <DataGrid rows={rows} columns={columns} pageSize={5} />
              </div>
              </>
               )} else {
                return (<></>)
               } }
               else {
           return (<>...</>)
       }

}