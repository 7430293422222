import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom'
import { appconfig } from '../karumaconfig'
import {useMoralis} from "react-moralis";
import { LiveTokenBalance } from '../components/LiveTokenBalance';
import { DonationTable } from '../components/DonationTable';
import { TokenBalance } from '../components/TokenBalance';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import FormLabel from '@material-ui/core/FormLabel'
import Paper from '@material-ui/core/Paper';
import { Container, Divider, Typography } from '@material-ui/core';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => {
    return {
      page: {
        background: '#f9f9f9',
        width: '100%',
        padding: theme.spacing(3),
      },
      root: {
        display: 'flex',
      },
      active: {
        background: '#f4f4f4'
      },
      title: {
        padding: theme.spacing(2),
      },
      date: {
        flexGrow: 1
      },
      toolbar: theme.mixins.toolbar,
      avatar: {
        marginLeft: theme.spacing(2)
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
      },
      form: {
        margin: 20,
        minWidth: 120,
        width: '100%',
      },
      menuItem:{
          width: '100%',
      },
      field: {
        marginTop: 20,
        marginBottom: 20,
        display: 'block'
      },
      button: {
        marginTop: 20,
        marginBottom: 20,
        display: 'block'
      },
      root: {
          display: 'flex',
          '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(32),
          }
      }
    }
  })


  

export default function Donate(props) {
    const { authenticate, Moralis, isAuthenticated, isInitialized, user } = useMoralis()
    const classes = useStyles()
    const history = useHistory();
    const [amount, setAmount] = useState('')
    const [amountError, setAmountError] = useState(false)
    const [address, setAddress] = useState('')
    const [addressError, setAddressError] = useState(false)
    const [options, setOptions] = useState(null);
    
    var [projectId, setProjectId] = useState()
    
    var results

    function createPledge(projectId,amount){
        const Organization = Moralis.Object.extend("Organization");
        var organization = new Organization();

        const Project = Moralis.Object.extend("Project");
        var project = new Project();

        const Donation = Moralis.Object.extend("Donation");
        const donation = new Donation();

         // Do checks on input values [TODO]

        // Fetch data on project
            const query = new Moralis.Query(Project);
            query.get(projectId).then( (projectInfo) => {
                project = projectInfo;
                console.log(project)
                const orgquery = new Moralis.Query(Organization);
                const organizationId = project.attributes.organization.id;
                orgquery.get(organizationId).then( (organizationInfo) => {
                    organization = organizationInfo;
                    console.log(organization)
                    donation.set("status", "pledged");
                    donation.set("sender", user.attributes.ethAddress);
                    donation.set("recipient", organization.attributes.ethAddress);
                    donation.set("accountId",project.attributes.accountId)
                    donation.set("amount", (parseFloat(amount)*(10**appconfig.KRTDecimals)));
                    donation.set("sendTx", undefined);
                    donation.set("receiveTx", undefined);
                    donation.set("reference", project.attributes.name)
                    donation.set("user",user);
                    donation.set("project",project);

                    console.log(donation)

                    donation.save()
                        .then((donation) => {
                            // Execute any logic that should take place after the object is saved.
                            console.log('New pledge created with objectId: ' + donation.id);
                        }, (error) => {
                            // Execute any logic that should take place if the save fails.
                            // error is a Moralis.Error with an error code and message.
                            console.log('Failed to create new pledge object, with error code: ' + error.message);
                        });

                }, (error) => {
                    console.log(error)
                })
            }, (error) => {
                console.log(error)
            })

            

        

    }

    useEffect(() => {


        async function wrapper(){
    
                const Project = Moralis.Object.extend("Project");
                const query = new Moralis.Query(Project);
                // query.equalTo("status", "active");
                // query.equalTo("address", props.address);
                results = await query.find();
                //console.log(results);
                setOptions(results);
    
        }
    
        if(isInitialized){
    
                wrapper();
    
        }
    
        }, [isInitialized])
    
    const handleChange = (event) => {
        setProjectId(event.target.value);
        console.log(event.target.value + " selected")
      };

    
    const handleSubmit = (e) => {
        e.preventDefault()
        setAmountError(false)
    
        if (amount == '') {
          setAmountError(true)
        }
        if (projectId == '') {
          setAddressError(true)
        }
        if (amount && projectId) {
            createPledge(projectId, amount)
            //transferToken(address, amount).then(() => history.push('/'))
          
        } 
      }


    if (options != null) {return (
        <>
        <Paper variant="outlined" className={classes.root}>
        <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
        {/* Balance:  { isAuthenticated && <TokenBalance balanceExtend="EthTokenBalance" address={user.attributes.ethAddress} interval = {30} tokenaddress = {appconfig.chain.KRTAddress}/>} */}
          
        <TextField className={classes.field}
          onChange={(e) => setAmount(e.target.value)}
          label="Amount" 
          variant="outlined" 
          color="secondary" 
          fullWidth
          required
          error={amountError}
        />
        <FormControl required className={classes.formControl}>
                <InputLabel id="project-select-label">Project</InputLabel>
                <Select
                  labelId="project-select-label"
                  id="project-select"
                  value={projectId}
                  onChange={handleChange}
                >
        
                {options.map((item) => (
                    <MenuItem 
                        className={classes.menuItem}
                      value={item.id}
                      key={item.id} 
                    >
                        {item.attributes.name}
                    </MenuItem>
        
                    )
                    
                )}
                </Select>
                </FormControl>
        <Button
            className={classes.button}
          type="submit" 
          color="primary" 
          variant="contained">
          Pledge
        </Button>
        </form>
        </Paper>
        {/* <Container>
        Balance:  { isAuthenticated && <TokenBalance balanceExtend={"PolygonTokenBalance"} address={user.attributes.ethAddress} interval = {300} tokenaddress = {appconfig.KRTPolygonAddress}/> }
        </Container> */}
        <Divider variant="middle" />
        <DonationTable sender={user.attributes.ethAddress} status="pledged"/>
        
        <DonationTable sender={user.attributes.ethAddress} status="pending"/>

        {/* <Container>
        <Typography variant="subtitle2">
        KRT Balance on Polygon/Matic:  { isAuthenticated && <LiveTokenBalance balanceExtend={"MaticTokenBalance"} address={user.attributes.ethAddress} tokenaddress = {appconfig.KRTPolygonAddress}/> }
        </Typography>
        </Container>
        <Container>
        <Typography variant="subtitle2">
        KRT Balance on Ethereum:  { isAuthenticated && <LiveTokenBalance balanceExtend="EthTokenBalance" address={user.attributes.ethAddress} tokenaddress = {appconfig.chain.KRTAddress}/>}
        </Typography>
        </Container> */}
        </>
    )
    }
    else {
        return (<>Loading</>)
       
    }

}
