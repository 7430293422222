import React, {useEffect, useState} from 'react'
import {useMoralis, useMoralisQuery} from "react-moralis";
import { appconfig } from '../karumaconfig'
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Divider, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SendIcon from '@material-ui/icons/Send';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  table: {
    minWidth: 250,
  },
  tableContainer:{
    marginTop: 20,
    marginBottom: 20,
  },
});


export function DonationTable(props) {
    const classes = useStyles();
    var [data, setData] = useState();
    var [totalAmount, setTotalAmount] = useState(0);
    var query;
    var subscription;
    //console.log(props)


    const { authenticate, Moralis, isAuthenticated, isInitialized, user } = useMoralis()
    const history = useHistory();
    

    

    useEffect(() => {

        function getSum(total, row) {
          return total + row.attributes.amount;
        }

        async function loadData(){
            const Donation = Moralis.Object.extend("Donation");
            query = new Moralis.Query(Donation);
            if (props.status){
                query.equalTo("status", props.status);
            }
            if (props.sender){
                query.equalTo("sender", props.sender);
            }
            const results = await query.find();
            //console.log(results);
            setData(results);

            setTotalAmount(results.reduce(getSum, 0));

        }

      
        async function wrapper(){
            //console.log(isInitialized)
            
            loadData();

            subscription = await query.subscribe();

            subscription.on('open', () => {
                //console.log('subscription opened');
               });
        
            subscription.on('create', (object) => {
                //console.log('object updated');
                loadData();
              });

            subscription.on('update', (object) => {
            //console.log('object updated');
                loadData();
            });

            subscription.on('delete', (object) => {
            //console.log('object updated');
                loadData();
            });
    
        }
    
        if(isInitialized){

                wrapper();

        }

        }, [isInitialized])

        async function handleSendClick(sender, recipient, amount, donationId){
            console.log("send " + amount + " amount from: " + sender + " to: " + recipient + " requested for " + donationId);
            let web3 = await Moralis.Web3.enable();
            const tokencontract = new web3.eth.Contract(appconfig.krtabi, appconfig.chain.KRTAddress);

                console.log(tokencontract);

                await tokencontract.methods.transfer(recipient, amount).send({from: sender}, function(err, txHash){
                    if (err){
                        console.log(err)
                    } else {
                        console.log("safeTransfer request sent with txHash: " + txHash)
                        const Donation = Moralis.Object.extend("Donation");
                        const transquery = new Moralis.Query(Donation);
                        transquery.get(donationId)
                        .then((donation) => {
                            donation.set("sendTx", txHash);
                            donation.set("status", "sent"); // can go to pending and then sent 
                            donation.save();
                        // The object was retrieved successfully.
                        }, (error) => {
                        // The object was not retrieved successfully.
                        console.log(error);
                        // error is a Moralis.Error with an error code and message.
                        });
                    }
                });
        }

        
        async function handleToConsolidator(data, totalAmount){
          console.log("clicked to consolidator");
          // send total to consolidator
          let web3 = await Moralis.Web3.enable();
            const tokencontract = new web3.eth.Contract(appconfig.krtabi, appconfig.chain.KRTAddress);

                console.log(tokencontract);

                await tokencontract.methods.transfer(appconfig.chain.KRTConsolidatorAddress, totalAmount).send({from: user.attributes.ethAddress}, function(err, txHash){
                    if (err){
                        console.log(err)
                    } else {
                      // if success update all pledges status to consolidate and set sent txnid
                        // console.log("safeTransfer request sent with txHash: " + txHash)
                        // const Donation = Moralis.Object.extend("Donation");
                        // const transquery = new Moralis.Query(Donation);
                        // transquery.get(donationId)
                        // .then((donation) => {
                        //     donation.set("sendTx", txHash);
                        //     donation.set("status", "sent"); // can go to pending and then sent 
                        //     donation.save();
                        // // The object was retrieved successfully.
                        // }, (error) => {
                        // // The object was not retrieved successfully.
                        // console.log(error);
                        // // error is a Moralis.Error with an error code and message.
                        // });
                    }
                });

          

        }

    
    
        function handleDeleteClick(donationId){
            //console.log("delete clicked for " + donationId);
            const Donation = Moralis.Object.extend("Donation");
            const delquery = new Moralis.Query(Donation);
            delquery.get(donationId)
            .then((donation) => {
                donation.destroy()
            // The object was retrieved successfully.
            }, (error) => {
            // The object was not retrieved successfully.
            console.log(error);
            // error is a Moralis.Error with an error code and message.
            });
        }

        if (data != null) {
            if (data.length > 0){  
            //console.log(data)
               return (<>
                <TableContainer className={classes.tableContainer} component={Paper}>
                <Table className={classes.table} size="small" aria-label="donation table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Reference</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Status</TableCell>
                      {(props.status=="pledged") &&<TableCell align="right"></TableCell>}
                      {(props.status=="pledged") &&<TableCell align="right"></TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row.id} id={row.id}>
                        <TableCell component="th" scope="row">
                          {row.attributes.reference}
                        </TableCell>
                        <TableCell align="right">{(row.attributes.amount/(10**appconfig.KRTDecimals))}</TableCell>
                        <TableCell align="right">{row.attributes.status}</TableCell>
                        {(props.status=="pledged") && <TableCell><SendIcon fontSize="small" color="primary" onClick = {()=>handleSendClick(row.attributes.sender, row.attributes.recipient, row.attributes.amount, row.id)} /></TableCell>}
                        {(props.status=="pledged") &&<TableCell><DeleteForeverIcon fontSize="small" color="secondary" onClick = {()=>handleDeleteClick(row.id)} /></TableCell>}
                        
                      </TableRow>
                    ))}
                    <TableRow><TableCell  align="right"></TableCell>
                    {totalAmount && <TableCell align="right"><Typography>Total: {totalAmount/(10**appconfig.KRTDecimals)}</Typography></TableCell>}</TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <Container>
                {(props.status=="pledged" && data.length > 0) && <Button variant="contained" color="primary" onClick = {() => handleToConsolidator(data, totalAmount)}>Send all pledges to Consolidator</Button>}
                </Container></>
               )} else {
                return (<></>)
               } }
               else {
           return (<>...</>)
       }

}



    