import React, {useEffect, useState} from 'react'
import {useMoralis, useMoralisQuery} from "react-moralis";
import { appconfig } from '../karumaconfig'
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SendIcon from '@material-ui/icons/Send';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import green from '@material-ui/core/colors/green';
import blue from '@material-ui/core/colors/blue';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  table: {
    minWidth: 250,
  },
  tableContainer:{
    marginTop: 10,
    marginBottom: 10,
  },
  tableTitle:{
    marginTop: 15,
    marginBottom: 10,
  },
});


export function ServiceTable(props) {
    const classes = useStyles();
    var [data, setData] = useState();
    var [projectname, setProjectname] = useState();
    var query;
    var subscription;
    //console.log(props)


    const { authenticate, Moralis, isAuthenticated, isInitialized, user } = useMoralis()
    const history = useHistory();
    

    

    useEffect(() => {

        async function loadData(){
            const Service = Moralis.Object.extend("Service");
            query = new Moralis.Query(Service);
            query.equalTo("user",user);

           if (props.status){
                query.equalTo("status", props.status);
            }
            query.find().then( (results) => {
                console.log(results);
                setData(results);
            });
            

        }

      
        async function wrapper(){
            console.log(isInitialized)
            
            loadData();

            subscription = await query.subscribe();

            subscription.on('open', () => {
                //console.log('subscription opened');
               });
        
            subscription.on('create', (object) => {
                //console.log('object updated');
                loadData();
              });

            subscription.on('update', (object) => {
            //console.log('object updated');
                loadData();
            });

            subscription.on('delete', (object) => {
            //console.log('object updated');
                loadData();
            });
    
        }
    
        if(isInitialized){

                wrapper();

        }

        }, [isInitialized])

            
        function handleDeleteClick(serviceId){
            //console.log("delete clicked for " + donationId);
            const Service = Moralis.Object.extend("Service");
            const delquery = new Moralis.Query(Service);
            delquery.get(serviceId)
            .then((donation) => {
                donation.destroy()
            // The object was retrieved successfully.
            }, (error) => {
            // The object was not retrieved successfully.
            console.log(error);
            // error is a Moralis.Error with an error code and message.
            });
        }

        if (data != null) {
            if (data.length > 0){  
            //console.log(data)
               return (
                   <>
                   <Typography className={classes.tableTitle} variant="h6">Projects {props.status}</Typography>
                <TableContainer className={classes.tableContainer} component={Paper}>
                <Table id="service-table"className={classes.table} size="small" aria-label="service table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Project</TableCell>
                      <TableCell align="right">Status</TableCell>
                      {(props.status=="pending") &&<TableCell align="right"></TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row.id} id={row.id}>
                        <TableCell component="th" scope="row">
                          {row.attributes.project.attributes.name}
                        </TableCell>
                        <TableCell align="right">{row.attributes.status}</TableCell>
                        {(props.status=="pending") &&<TableCell><DeleteForeverIcon fontSize="small" color="secondary" onClick = {()=>handleDeleteClick(row.id)} /></TableCell>}
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              </>
               )} else {
                return (<></>)
               } }
               else {
           return (<>...</>)
       }

}



    