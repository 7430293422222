import React, {useEffect, useState} from 'react'
import {useMoralis, useMoralisQuery} from "react-moralis";
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom'

export function LiveTokenBalance(props) {

    var [data, setData] = useState();
    var subscription;
    //console.log(props)


    const { authenticate, Moralis, isAuthenticated, isInitialized, user } = useMoralis()
    const history = useHistory();

    

    useEffect(() => {

      
        async function wrapper(){
            //console.log(props.balanceExtend);
            //console.log(props.address)
            const TokenBalance = Moralis.Object.extend(props.balanceExtend);
            const query = new Moralis.Query(TokenBalance);
            query.equalTo("token_address", props.tokenaddress.toLowerCase());
            query.equalTo("address", props.address);
            const results = await query.find();
            //console.log(results);
            setData(results[0]);
            subscription = await query.subscribe();

            subscription.on('open', () => {
                //console.log('subscription opened');
               });
        
            subscription.on('update', (object) => {
                //console.log('object updated');
                setData(object);
              });
    
        }
    
        if(isInitialized){

                wrapper();

        }

        }, [isInitialized, user])
    

        if (data != null) {  
            //console.log(data)
               return (
                   <span>{parseFloat(data.attributes.balance)/(10**parseInt(data.attributes.decimals))} {data.attributes.symbol}</span>)
            }  else {
           return (<>...</>)
       }


}



    