import React from 'react'
import {useMoralis} from "react-moralis";
import {useState} from "react";
import { Container, Box, TextField, Button, makeStyles, Typography } from '@material-ui/core';

function ProfileForm(){

    const {user, setUserData, userError, isUserUpdating} = useMoralis();
    const [username, setUsername] = useState(user.attributes.username);
    const [email, setEmail] = useState(user.attributes.email);
    const [password, setPassword] = useState('');

    const handleSave = () => {
        setUserData({
            username,
            email,
            password: password === "" ? undefined : password
        })
    }

    return <Box>
                <Container>
                {/* { userError && <ErrorBox title="User change failed" message={userError.message} />} */}
                    <Box m={1} p={{ xs: 1, sm: 2, md: 3 }}>
                          <TextField variant="outlined" label="Username" value={username} onChange={(event) => setUsername(event.currentTarget.value)} />
                    </Box>
                    <Box m={1} p={{ xs: 1, sm: 2, md: 3 }}>
                        <TextField variant="outlined" label="Email" value={email} onChange={(event) => setEmail(event.currentTarget.value)} />
                    </Box>
                    <Box m={1} p={{ xs: 1, sm: 2, md: 3 }}>
                        <TextField variant="outlined" label="Password"type="password" value={password} onChange={(event) => setPassword(event.currentTarget.value)} />
                    </Box>
                    <Box m={1} p={{ xs: 1, sm: 2, md: 3 }}>
                        <Button variant="contained" color="primary" onClick={handleSave}>Save changes</Button>
                    </Box>
                </Container>
            </Box>
}


export default function Profile() {
    const {user, isAuthenticated, Moralis} = useMoralis();

   
    if (isAuthenticated){
        return (
        <div>
            {/* <Typography>Profile</Typography> */}
            <ProfileForm/>
        </div>
        )
    } else {
        return (
            <div>
                <Typography>Please Log in first</Typography>
            </div>
        )
    }
}
