import React from 'react'
import {useMoralis} from "react-moralis";
import {useState} from "react";
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom'
import { Container, Box, TextField, Button, makeStyles, Typography } from '@material-ui/core';
import { ErrorBox } from "./Error";

const useStyles = makeStyles((theme) => {
    return {
      page: {
        background: '#f9f9f9',
        width: '100%',
        padding: theme.spacing(3),
      },
      root: {
        display: 'flex',
      },
      textfield: {
        padding: theme.spacing(3),
        margin: 10,
      },
     
    }
  })

export default function LogIn() {
    const {Moralis, login, isAuthenticated, userError} = useMoralis();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const classes = useStyles();
    const history = useHistory();

    Moralis.Web3.getSigningData = () => 'Karmic Account - Sign in'

    if (isAuthenticated) {
      history.push("/donate");
      return(
        <Typography>You're logged in.</Typography>
        
      )
    } else 
      { if (userError) {
      return ( <ErrorBox title="User change failed" message={userError.message} />)}
        else {
            return(   
            <Container>
              <Container>
              <Box m={1} p={{ xs: 1, sm: 2, md: 3 }}><TextField  className="classes.textfield" variant="outlined" label="Email" value={email} onChange={(event) => setEmail(event.currentTarget.value)} /></Box>
              <Box m={1} p={{ xs: 1, sm: 2, md: 3 }}><TextField className="classes.textfield"  variant="outlined" label="Password" type="password" value={password} onChange={(event) => setPassword(event.currentTarget.value)} /></Box>
              <Box m={1} p={{ xs: 1, sm: 2, md: 3 }}><Button variant="contained" color="primary" onClick={() => login(email,password)}>Log in</Button></Box>
              </Container>
              <Typography gutterBottom>
                If you don't have an account yet, please create one below.
              </Typography>

                <SignUp/>

            </Container>
            );
          }
        }
  }

const SignUp = () => {
    const {signup} = useMoralis();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
  
  
    return (<Container><form noValidate autoComplete="off">
    <Box m={1} p={{ xs: 1, sm: 2, md: 3 }}><TextField label="Email" variant="outlined" value={email} onChange={(event) => setEmail(event.currentTarget.value)} /></Box>
    <Box m={1} p={{ xs: 1, sm: 2, md: 3 }}><TextField label="Password" variant="outlined" type="password" value={password} onChange={(event) => setPassword(event.currentTarget.value)} /></Box>
    <Box m={1} p={{ xs: 1, sm: 2, md: 3 }}><Button variant="contained" color="primary" onClick={() => signup(email,password,email)}>Sign up</Button></Box></form>
    </Container>);
  };
