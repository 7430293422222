import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import {useMoralis, useMoralisQuery} from "react-moralis";

import { appconfig } from '../karumaconfig'

import { Container, makeStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField';

import Divider from '@material-ui/core/Divider';

import Button from '@material-ui/core/Button'

import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import CancelIcon from '@material-ui/icons/Cancel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { yellow, green, pink, blue } from '@material-ui/core/colors'
import SendIcon from '@material-ui/icons/Send';
import Box from '@material-ui/core/Box';
import { CodeSharp } from '@material-ui/icons';



const useStyles = makeStyles((theme) => ({
    avatar: {
    backgroundColor: (nft) => {
      return blue[500]
    }},
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    margined: {
      margin: 10, 
    },
    sendFormField: {
      width: '70%',
    },
    sendFormButton: {
      verticalAlign: 'middle',
      width: '30%',
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
}))



export default function NFTCard({ nft, tokensOnSale}) {
  const { authenticate, Moralis, isAuthenticated, isInitialized, user } = useMoralis();
  const classes = useStyles(nft);
  var [tokenMetaData, setTokenMetaData] = useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [address, setAddress] = useState();
  const [addressError, setAddressError] = useState();
  const [NFTPrice, setNFTPrice] = useState();
  const history = useHistory()

  var marketInst;
  //var [tokenURI, setTokenURI] = useState();
  var tokencontract;
  const [offer, setOffer] = useState();
  const web3Lib = new Moralis.Web3();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  
  useEffect( () => {
    async function wrapper() {

    try {
    let web3 = await Moralis.Web3.enable();
    //console.log(web3);
    marketInst = await new web3.eth.Contract(appconfig.kmcmarketplaceabi, appconfig.chain.KMCMarketAddress);
    //console.log(marketInst);

    tokencontract = await new web3.eth.Contract(appconfig.kmcabi, appconfig.chain.KMCAddress);
    //console.log("NFT contract: ");
    //console.log(tokencontract);
  }
  catch(err){
    console.log(err)
  }

    //console.log(nft.attributes.token_uri)
    fetch(nft.attributes.token_uri)
      .then(res => {
        //console.log(res);
        return res.json();
      })
      .then(data => {
        if (data == null){
            data = { name: "Unnamed", description : "No Description"}
        }
        //console.log(data);
        setTokenMetaData(data);
      })
    }

    wrapper();

    }
    , [user, isInitialized])


    async function handleSendClick(from, recipient, tokenId){
      if (from && recipient && tokenId){
        //console.log("send " + amount + " amount from: " + sender + " to: " + recipient + " requested for " + donationId);
        let web3 = await Moralis.Web3.enable();
        const NFTcontract = new web3.eth.Contract(appconfig.kmcabi, appconfig.chain.KMCAddress);

        //console.log(NFTcontract);

        await NFTcontract.methods.safeTransferFrom(from, recipient, tokenId).send({from: from}, function(err, txHash){
            if (err){
                console.log(err)
            } else {
                console.log("safeTransfer request sent with txHash: " + txHash)
                history.go(0)
            }
        });


      } else {
        console.log(user);
        console.log(from + " " + recipient + " " + tokenId);
      }
    }

    async function cancelOffer(tokenId){
      let web3 = await Moralis.Web3.enable();
      console.log(web3);
      marketInst = await new web3.eth.Contract(appconfig.kmcmarketplaceabi, appconfig.chain.KMCMarketAddress);
      marketInst.methods.removeOffer(tokenId).send({from: user.attributes.ethAddress}, function(err, txHash){
          if (err){
              console.log(err)
          } else {
              console.log("Cancel offer request sent with txHash: " + txHash)
              history.go(0)
          }
      });
  }

    async function handleSellClick(from, price, tokenId){

      console.log(from);
        console.log(price);
        console.log(tokenId);

      if (from && price && tokenId){
        let web3 = await Moralis.Web3.enable();
        marketInst = await new web3.eth.Contract(appconfig.kmcmarketplaceabi, appconfig.chain.KMCMarketAddress);
        //console.log(marketInst);
        var ethTokenPrice = price;
        var weiTokenPrice = web3Lib.utils.toWei(ethTokenPrice);
      //console.log("Put on offer token "+tokenId+" at price of "+ethTokenPrice+" ETH or "+weiTokenPrice+" in wei.");

        marketInst.methods.setOffer(weiTokenPrice, tokenId).send({from: from}, function(err, txHash){
            if (err){
                console.log(err)
            } else {
                console.log("setOffer request sent with txHash: " + txHash)
                history.go(0)
            }
        });

      } else {
        console.log(user);
        console.log(from + " " + price + " " + tokenId);
      }
    }

  if (tokenMetaData != null){
    //console.log(tokenMetaData);
    return (
      <div>
        <Card id={nft.id} elevation={1}>
          <CardHeader
            avatar = {<Avatar alt="Token avatar" src={tokenMetaData.image} />}
            title={tokenMetaData.name}
            subheader={nft.attributes.name + " with id: " + nft.attributes.token_id}
          />
          <Divider variant="middle" />
          <CardMedia
            className={classes.media}
            image={tokenMetaData.image}
            title=""
          />
          <Divider variant="middle" />
          <CardContent>
            <Typography variant="body2" color="textSecondary">
              { tokenMetaData.description }
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
          {(tokensOnSale.includes(nft.attributes.token_id)) && <><IconButton aria-label="cancel" onClick={() => {cancelOffer(nft.attributes.token_id)}}>
          <Typography className = {classes.margined}>Cancel sale</Typography> <CancelIcon />
            </IconButton></>}
            {/* <IconButton aria-label="add to favorites">
              <FavoriteIcon />
            </IconButton>
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton> */}
            {!(tokensOnSale.includes(nft.attributes.token_id)) && <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>}
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Divider variant="middle" />
        <CardContent>
          <Typography paragraph>Sell</Typography>
          <Typography paragraph>If you continue you will be charged for using the market place.</Typography>
          <Typography paragraph>By selling your karma certificate you will also be transfering the past, present and future karma connected to it.</Typography>
          <form>
          <Box className = {classes.sendFormField} component="span" m={1}>
          <TextField className={classes.field}
          onChange={(e) => setNFTPrice(e.target.value)}
          label="Price" 
          variant="outlined" 
          color="secondary" 
          // fullWidth
          required
          error={addressError}
        /></Box>
        <Box className = {classes.sendFormButton} component="span" m={1}><Button className={classes.button}
          //type="submit" 
          onClick = {()=>handleSellClick(user.attributes.ethAddress, NFTPrice ,nft.attributes.token_id)}
          color="primary" 
          variant="contained">Put for sale
        </Button>
        </Box>
          </form>
        </CardContent>
        <Divider variant="middle" />
        <CardContent>
          <Typography paragraph>Transfer</Typography>
          <Typography paragraph>Caution: All transfers are final and irreversibel please make sure the recipient address is correct or you may loose your token permanently.</Typography>
          <Typography paragraph>By transfering your karma certificate you will also be transfering the past, present and future karma connected to it.</Typography>
          <form>
          <Box className = {classes.sendFormField} component="span" m={1}>
          <TextField className={classes.field}
          onChange={(e) => setAddress(e.target.value)}
          label="Address" 
          variant="outlined" 
          color="secondary" 
          // fullWidth
          required
          error={addressError}
        /></Box>
        <Box className = {classes.sendFormButton} component="span" m={1}><Button className={classes.button}
          //type="submit" 
          onClick = {()=>handleSendClick(user.attributes.ethAddress, address,nft.attributes.token_id)}
          color="primary" 
          variant="contained">Send
        </Button>
        </Box>
          </form>
        </CardContent>
      </Collapse>
        </Card>
      </div>
    )
  } else {
    return(
    <div>
      <Card elevation={1}>
        <CardHeader
          title="Loading"
          subheader={nft.category}
        />
        <Divider variant="middle" />
        <CardContent>
          <Typography variant="body2" color="textSecondary">
            Fetching metadata...
          </Typography>
        </CardContent>
      </Card>
    </div>)
  }
}