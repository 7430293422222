import React, { useEffect, useState } from 'react'
import { appconfig } from '../karumaconfig'
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom'
import {useMoralis, useMoralisQuery} from "react-moralis";
import Container from '@material-ui/core/Container'
import NFTCard from '../components/NFTCard'
import { Typography } from '@material-ui/core';
import Masonry from 'react-masonry-css'




export default function NFTs() {
  var [NFTs, setNFTs] = useState([]);
  var [data, setData] = useState();
  var [tokensOnSale, setTokensOnSale] = useState([]);
  var query;
  var subscription;

  const { authenticate, Moralis, isAuthenticated, isInitialized, user } = useMoralis()
  const history = useHistory();

  async function loadData(){

    const EthNFT = Moralis.Object.extend("EthNFTOwners");
      query = new Moralis.Query(EthNFT);
      query.equalTo("owner_of", user.attributes.ethAddress);
      query.equalTo("token_address", appconfig.chain.KMCAddress.toLowerCase);

      const results = await query.find();
      const tokenlist = await getAllTokenOnSale()
      //console.log(tokenlist);
      setTokensOnSale(tokenlist);
      //console.log(results);
      const newlist = results.filter((e) => {if (tokenlist.includes(e.attributes.token_id)) {return false} else {return true}});
      //console.log(newlist);
      setData(results);
      //setData(newlist);

  }

  async function getAllTokenOnSale(){
    let web3 = await Moralis.Web3.enable();
    //console.log(web3);
    let marketInst = await new web3.eth.Contract(appconfig.kmcmarketplaceabi, appconfig.chain.KMCMarketAddress);
    try{
    let result = await marketInst.methods.getAllTokenOnSale().call({from: user.attributes.ethAddress})
  
        // console.log(result);
        return result;
    } catch(err) {
        console.log(err);
    }
  }
  
  useEffect(() => {

    async function wrapper(){

      loadData();

      subscription = await query.subscribe();

            subscription.on('open', () => {
                //console.log('subscription opened');
               });
        
            subscription.on('create', (object) => {
                //console.log('object updated');
                loadData();
              });

            subscription.on('update', (object) => {
            //console.log('object updated');
                loadData();
            });

            subscription.on('delete', (object) => {
            //console.log('object updated');
                loadData();
            });
    }
  
    if(isInitialized){
      
      if (!isAuthenticated) {
        history.push("/login");
      } else {
        wrapper();
      }
    }
  }, [isInitialized])

  // const { data : qdata, error, isLoading } = useMoralisQuery("EthNFTOwners", query =>
  // query
  //   .equalTo("owner_of", user.attributes.ethAddress));


 


  
  const breakpoints = {
    default: 3,
    1100: 2,
    700: 1
  };

  

  if (data == null) {
    return (<Typography>Loading...</Typography>)
  } else {
    //console.log(data);

    return (
      <Container>
        {/* {isLoading == true && (
          <div>Loading...</div>
        )} */}
        <Masonry
            breakpointCols={breakpoints}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column">
            {data.map(NFT => (
                <div key={NFT.id}>
                  <NFTCard nft={NFT} tokensOnSale={tokensOnSale} />
                </div>
              ))}
          </Masonry>
      </Container>
    )
  }

}