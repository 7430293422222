import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import {ByMoralis, useMoralis} from "react-moralis";
import { MoralisProvider } from "react-moralis";
import NFTs from './pages/NFTs'
import Create from './pages/Create'
import LogIn from './pages/LogIn'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { purple } from '@material-ui/core/colors'
import Layout from './components/Layout'
import Profile from './pages/Profile';
import Donate from './pages/Donate';
import Volunteer from './pages/Volunteer';
import AllEyes from './pages/AllEyes';
import NFTMarketPlace from './pages/MarketPlace';

//Sendgrid api key: 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

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#7a1f7f'
    },
    secondary: {
      main: '#e41b13'
    },
    success: {
      main: '#4caf50'
    }
  },
  typography: {
    fontFamily: 'Quicksand',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  }
})

function App() {
  
  return (
    <MoralisProvider appId="XXsoTOaM7NjSDcAMpmyzgLFOVIKXfiRVFR9N76VF" serverUrl="https://gjamkbokmuem.moralis.io:2053/server">
      <ThemeProvider theme={theme}>
        <Router>
          <Layout>
            <Switch>
              <Route exact path="/">
              <LogIn />
              </Route>
              <Route path="/donate">
                <Donate />
              </Route>
              <Route path="/volunteer">
                <Volunteer />
              </Route>
              <Route path="/karma-certificates">
                <NFTs />
              </Route>
              <Route path="/marketplace">
                <NFTMarketPlace />
              </Route>
              <Route path="/all-eyes">
                <AllEyes />
              </Route>
              <Route path="/login">
                <LogIn />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
            </Switch>
          </Layout>
        </Router>
      </ThemeProvider>
    </MoralisProvider>
  );
}

export default App;
