import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, Link, Redirect } from 'react-router-dom'
import { appconfig } from '../karumaconfig'
import {useMoralis} from "react-moralis";
import { LiveTokenBalance } from '../components/LiveTokenBalance';
import { ServiceTable } from '../components/ServiceTable';
import { TokenBalance } from '../components/TokenBalance';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import FormLabel from '@material-ui/core/FormLabel'
import Paper from '@material-ui/core/Paper';
import { Container, Divider, Typography } from '@material-ui/core';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => {
    return {
      page: {
        background: '#f9f9f9',
        width: '100%',
        padding: theme.spacing(3),
      },
      root: {
        display: 'flex',
      },
      active: {
        background: '#f4f4f4'
      },
      title: {
        padding: theme.spacing(2),
      },
      date: {
        flexGrow: 1
      },
      toolbar: theme.mixins.toolbar,
      avatar: {
        marginLeft: theme.spacing(2)
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%',
      },
      form: {
        margin: 20,
        minWidth: 120,
        width: '100%',
      },
      menuItem:{
          width: '100%',
      },
      field: {
        marginTop: 20,
        marginBottom: 20,
        display: 'block'
      },
      button: {
        marginTop: 20,
        marginBottom: 20,
        display: 'block'
      },
      root: {
          display: 'flex',
          '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(32),
          }
      }
    }
  })


  

export default function Volunteer(props) {
    const { authenticate, Moralis, isAuthenticated, isInitialized, user } = useMoralis()
    const classes = useStyles()
    const history = useHistory();
    const [message, setMessage] = useState('')
    const [messageError, setMessageError] = useState(false);
    const [projectError, setProjectError] = useState(false)
    const [options, setOptions] = useState(null);
    
    var [projectId, setProjectId] = useState()
    
    var results

    function createApplication(projectId,message){
      const Service = Moralis.Object.extend("Service");
      var service = new Service();

      const Project = Moralis.Object.extend("Project");
      var project = new Project();

        // Do checks on input values [TODO]

      // Fetch data on project
      const query = new Moralis.Query(Project);
      query.get(projectId).then( (projectInfo) => {
          project = projectInfo;
          console.log(project)
          
          service.set("status", "pending");
          service.set("project", project);
          service.set("user", Moralis.User.current());
          service.set("message", message);

          console.log(service)

          service.save()
              .then((donation) => {
                  // Execute any logic that should take place after the object is saved.
                  console.log('New pledge created with objectId: ' + donation.id);
              }, (error) => {
                  // Execute any logic that should take place if the save fails.
                  // error is a Moralis.Error with an error code and message.
                  console.log('Failed to create new pledge object, with error code: ' + error.message);
              });


      }, (error) => {
          console.log(error)
      })
    }

    useEffect(() => {


        async function wrapper(){
    
                const Project = Moralis.Object.extend("Project");
                const query = new Moralis.Query(Project);
                // query.equalTo("status", "active");
                // query.equalTo("address", props.address);
                results = await query.find();
                console.log(results);
                setOptions(results);
    
        }
    
        if(isInitialized){
    
                wrapper();
    
        }
    
        }, [isInitialized])
    
    const handleChange = (event) => {
        setProjectId(event.target.value);
        console.log(event.target.value + " selected")
      };

    
    const handleSubmit = (e) => {
        e.preventDefault()
        setMessageError(false)
    
        if (message == '') {
          setMessageError(true)
        }
        if (projectId == '') {
          setProjectError(true)
        }
        if (message && projectId) {
          createApplication(projectId, message);
            //transferToken(address, amount).then(() => history.push('/'))
          
        } 
      }


    if (options != null) {return (
        <>
        <Paper variant="outlined" className={classes.root}>
        <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
        {/* Balance:  { isAuthenticated && <TokenBalance balanceExtend="EthTokenBalance" address={user.attributes.ethAddress} interval = {30} tokenaddress = {appconfig.KRTEthAddress}/>} */}
          
        
        <FormControl required className={classes.formControl}>
                <InputLabel id="project-select-label">Project</InputLabel>
                <Select
                  labelId="project-select-label"
                  id="project-select"
                  value={projectId}
                  onChange={handleChange}
                >
        
                {options.map((item) => (
                    <MenuItem 
                        className={classes.menuItem}
                      value={item.id}
                      key={item.id} 
                    >
                        {item.attributes.name}
                    </MenuItem>
        
                    )
                    
                )}
                </Select>
        </FormControl>
        <TextField className={classes.field}
        onChange={(e) => setMessage(e.target.value)}
        label="Motivation" 
        variant="outlined" 
        color="secondary" 
        fullWidth
        required
        error={messageError}
        />
        <Button
            className={classes.button}
          type="submit" 
          color="secondary" 
          variant="contained">
          Apply
        </Button>
        </form>
        </Paper>
        <ServiceTable status="active" />
        <ServiceTable status="pending" />
        </>
    )
    }
    else {
        return (<>Loading</>)
       
    }

}
